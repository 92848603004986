import React, { Component } from 'react';
import {Form, Grid, Header, Segment, Loader, Icon} from 'semantic-ui-react'
import PubSub from 'pubsub-js';
import { getUserCod } from '../../servicos/authenticated';
import { getToken } from '../../servicos/authenticated';
import { logout } from '../../servicos/authenticated';

class TrocaSenha extends Component {
    constructor(props){
    super(props);
    
    this.state = {
      visible: false,
      username: '',
      password: '',
      error: '',
      succes: '',
      loader: false,
      isToggleOn: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  
   componentDidMount(){
      this.setState({visible: true});
  }
  
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  
    handleSubmit(event) {
    this.setState({loader: true})
      if (this.state.password === '') {
        this.setState({error: "Senha não pode ser vazia! Tente novamente."})
        this.setState({succes: ''})
        this.setState({loader: false})
    } else {
      var request = require('request');
      var options = {
        method: 'PUT',
        url:'https://inteligenciaindustrial.com/prodbeer/usuario/alteraSenha',
        headers: {
          'Authorization': getToken()
        },
        body: {"usuario": getUserCod(), "senha": this.state.password},
        json: true
      };

      request(options, function (error, response, body) {
        if (body !== undefined) {
          if (body.Usuario){
            this.setState({loader: false})
            this.setState({error: ''})
            this.setState({succes: "Senha alterada com sucesso!."})
          }else{
            PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
            PubSub.publish('logado', { logado:false });
            logout()
          }
        } else {
          this.setState({error: 'Não foi possivel conectar-se a internet tente novamente', loader: false});
        }
      }.bind(this));
      event.preventDefault();
    }
  }
  
  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }
  handleClickVoltar(){
    PubSub.publish('opcaoTela', {opcaoTela:''});
  }
  
  render() {
    
    return (
      <div>
        <style>{`body > div, body > div > div, body > div > div > div.login-form { height: 100%; } `}</style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: '350px' }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={14}>
                 <Header as='h5'>ALTERAR SENHA</Header>
                </Grid.Column>
                <Grid.Column width={2}>
                  <Icon floated='right' name='reply' onClick={this.handleClickVoltar}/><br/>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br/><br/>
              <Form size='large' onSubmit={this.handleSubmit}>
                <Segment stacked>
                  <Form.Input fluid icon='lock' iconPosition='left' placeholder='Nova Senha' type="password" 
                   name = "password" onChange={this.handleChange}/>
                  {
                    this.state.loader &&
                    <Loader active />
                  }
                  <button class="ui black large fluid icon right labeled button" > SALVAR
                    <i aria-hidden="true" class="save outline"></i>
                  </button>
                </Segment>
              </Form>
              {
                this.state.error &&
                  <div class="ui red message" style={{backgroundColor: '#ffe8e6' }}>
                    {this.state.error}
                </div>
              }
              {
                this.state.succes &&
                  <div class="ui green message" style={{backgroundColor: '#fbfbfb' }}>
                    {this.state.succes}
                </div>
              }
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default TrocaSenha;