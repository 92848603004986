import React, { Component } from 'react';
import { isAuthenticated, getUserCod, versao } from './servicos/authenticated';
import PubSub from 'pubsub-js';

import Login from './componentes/login/login';
import Principal from './componentes/principal/principal';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      logado: false
    };
  }
  componentDidMount(){
    PubSub.subscribe('logado', function(topic,data){
          this.setState({logado: data.logado})
        }.bind(this))
  }
  
  registrarAcesso() {
    let request = require('request');
    let options = {
      method: 'POST',
      url:'https://inteligenciaindustrial.com/prodbeer/acesso/acesso',
      headers: {
        'content-type': 'application/json'
      },
      body: {"codUsuario": getUserCod(), plataforma:"GetDataInd-Mobile", codigo_controle_versao: versao},
      json: true
    };
    request(options, function (error, response, body) {
    }.bind(this));
  }
  
  render() {
    let obj;
    if (isAuthenticated()) {
      this.registrarAcesso();
      obj = <Principal />
    } else {
      obj = <Login />
    }
    return(
      obj
    );
  }
}

export default App;
