import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import { Button, Grid, Header, Icon } from 'semantic-ui-react'

export default class menuCadastrarEditar extends Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }
  handleClick(Opcao) {
    if (Opcao === 'CADASTRAR') {
      PubSub.publish('opcaoTela', {opcaoTela:'Cadastrar'})
    } else if(Opcao === 'EDITAR') {
      PubSub.publish('opcaoTela', {opcaoTela:'ConsEditarExcluir'})
    }
  }
  handleClickVoltar(){
    PubSub.publish('opcaoTela', {opcaoTela: 'MenuLinhas' });
  }
  render() {
    const nomeFilial = this.props.arrayFiliais.filter((info) => {
      return info.COD_FILIAL === this.props.codFilial
    })
    const nomeLinha = this.props.arrayLinhasProducao.filter((info) => {
      return info.COD_LINHA_PRODUCAO === this.props.codLinha
    })
    return (
      <div>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='top'>
          <Grid.Column style={{ maxWidth: '350px' }}>            
             <Grid>
              <Grid.Row>
                <Grid.Column width={1}>
                </Grid.Column>
                <Grid.Column width={13}>
                 <Header as='h5'>O QUE DESEJA FAZER EM {nomeFilial[0].DESC_FILIAL} {nomeLinha[0].DESC_LINHA}</Header>
                </Grid.Column>
                <Grid.Column width={1}>
                  <Icon name='reply'onClick={this.handleClickVoltar} /><br/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button style={{width: "156px", height: "80px"}} 
                          onClick={this.handleClick.bind(this, 'CADASTRAR')} >CADASTRAR</Button>
                  <Button style={{width: "156px", height: "80px"}} 
                          onClick={this.handleClick.bind(this, 'EDITAR')}>EDITAR/EXCLUIR</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}