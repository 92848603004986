import React, { Component } from 'react';
import { Header, Grid, Icon, Image, Divider, Sidebar, Menu, Segment } from 'semantic-ui-react';
import { logout } from '../../servicos/authenticated';
import logoGetDataInd from '../../img/getdataind.png';
import PubSub from 'pubsub-js';
import { getToken } from '../../servicos/authenticated';

import MenuFabricas from '../menus/menu-fabricas';
import MenuLinhas from '../menus/menu-linhas';
import MenuCadastrarEditar from '../menus/menu-cadastrar-editar';
import ManterProducao from '../manter-producao/manter-producao';
import ConsEditaExclui from '../cons-edita-exclui/cons-edita-exclui'
import TrocaSenha from '../troca-senha/troca-senha';

class Principal extends Component {
  constructor(props){
    super(props);
    this.state = {
      visibleMenu: false,
      opcaoTela: '',
      arrayProducao:[],
      arrayProducaoEdit:[],
      arrayProdutos:[],
      arrayFiliais:[],
      arrayLinhasProducao:[],
      arrayProdutosLinha:[],
      arrayPerfis: [],
      arrayUsuario: [],
      codFilial:'',
      codLinha:'',
      idProducao:''
    };
    this.handleShowHideSidebar = this.handleShowHideSidebar.bind(this);
    this.trocaSenha = this.trocaSenha.bind(this);
    this.telaPrincipal = this.telaPrincipal.bind(this);
  }
  
  handleShowHideSidebar() {
    const { visibleMenu } = this.state;
    this.setState({
      visibleMenu: visibleMenu === true ? false : true
    });
  }
  logout() {
    logout()
    PubSub.publish('logado', { logado:false });
  }
  
  trocaSenha(){
    PubSub.publish('opcaoTela', { opcaoTela:'AlterarSenha' });
  }
  
  telaPrincipal() {
    PubSub.publish('opcaoTela', { opcaoTela:'' });
    this.setState({fabrica:'', linha:''});
  }
  componentDidMount(){
    //FAZ O REQUEST DOS DADOS NO BANCO DE DADOS
    let request = require('request');
    const optionsUsuario = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/prodbeer/usuario/usuarios',
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    const optionsProducao = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/prodbeer/producao/producoes',
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    const optionsProduto = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/prodbeer/produto/produtos',
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    const optionsFilial = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/prodbeer/filial/filiais',
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    const optionsLinhaProducao = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/prodbeer/linhaproducao/linhasProducao',
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    const optionsProducaoEdit = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/prodbeer/producao/producoesEdit',
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    const optionsProdutoLinha = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/prodbeer/produto/produtosLinha',
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    const optionsPerfis = {
      method: 'GET',
      url: 'https://inteligenciaindustrial.com/prodbeer/usuario/perfis',
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    request(optionsFilial, function (error, response, body) {
      if (body.Filiais) {
        this.setState({
          arrayFiliais:body.Filiais
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        logout()
      }
    }.bind(this));
    request(optionsUsuario, function (error, response, body) {
      if (body.Usuarios) {
        this.setState({
          arrayUsuario:body.Usuarios
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        logout()
      }
    }.bind(this));
    request(optionsProducao, function (error, response, body) {
      if (body.Producoes) {
        this.setState({
          arrayProducao:body.Producoes
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        logout()
      }
    }.bind(this));
    request(optionsProduto, function (error, response, body) {
      if (body.Produtos) {
        this.setState({
          arrayProdutos:body.Produtos
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        logout()
      }
    }.bind(this));
    request(optionsProdutoLinha, function (error, response, body) {
      if (body.ProdutosLinha) {
        this.setState({
          arrayProdutosLinha:body.ProdutosLinha
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        logout()
      }
    }.bind(this));
    request(optionsLinhaProducao, function (error, response, body) {
      if (body.LinhasProducao) {
        this.setState({
          arrayLinhasProducao:body.LinhasProducao
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        logout()
      }
    }.bind(this));
    request(optionsProducaoEdit, function (error, response, body) {
      if (body.ProducoesEdit) {
        this.setState({
          arrayProducaoEdit:body.ProducoesEdit
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        logout()
      }
    }.bind(this));
    request(optionsPerfis, function (error, response, body) {
      if (body.Perfis) {
        this.setState({
          arrayPerfis:body.Perfis
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        logout()
      }
    }.bind(this));
  }
  componentWillMount(){
    PubSub.subscribe('opcaoTela', function(topic,data){
      this.setState({opcaoTela: data.opcaoTela})
    }.bind(this));
    PubSub.subscribe('codFilial', function(topic,data){
      this.setState({codFilial: data.codFilial})
    }.bind(this));
    PubSub.subscribe('codLinha', function(topic,data){
      this.setState({codLinha: data.codLinha})
    }.bind(this));
    PubSub.subscribe('idProducao', function(topic,data){
      this.setState({idProducao: data.idProducao})
    }.bind(this));
    PubSub.subscribe('atualizaProducao', function(topic, data){
      if (data.atualizaProducao){
        this.atualizaProducao();
      }
    }.bind(this));
    PubSub.subscribe('atualizaProducaoEdit', function(topic, data) {
      if (data.atualizaProducaoEdit) {
        this.atualizaProducaoEdit();
      }
    }.bind(this));
  }
  atualizaProducao(){
    let request = require('request');
    const optionsProducao = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/prodbeer/producao/producoes',
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    request(optionsProducao, function (error, response, body) {
      if (body.Producoes) {
        this.setState({
          arrayProducao:body.Producoes
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        logout()
      }
    }.bind(this));
    
  }
  atualizaProducaoEdit() {
    let request = require('request');
    const optionsProducaoEdit = {
      method: 'GET',
      url:'https://inteligenciaindustrial.com/prodbeer/producao/producoesEdit',
      headers : {
        'Authorization': getToken()
      },
      json: true
    };
    request(optionsProducaoEdit, function (error, response, body) {
      if (body.ProducoesEdit) {
        this.setState({
          arrayProducaoEdit:body.ProducoesEdit
        });
      } else if(body.error === 'erro no token'){
        PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
        PubSub.publish('logado', { logado:false });
        logout()
      }
    }.bind(this));
  }
  
  render(){
    let { visibleMenu, opcaoTela} = this.state,
        obj;
    switch(opcaoTela) {
      case '':
        obj = <MenuFabricas arrayFiliais = {this.state.arrayFiliais} 
                            arrayUsuario = {this.state.arrayUsuario}
                            arrayPerfis = {this.state.arrayPerfis}/>
          break;
      case 'MenuLinhas':
        obj = <MenuLinhas codFilial = {this.state.codFilial} 
                          arrayFiliais = {this.state.arrayFiliais}
                          arrayLinhasProducao = {this.state.arrayLinhasProducao} />
          break;
      case 'MenuCadEdit':
        obj = <MenuCadastrarEditar codFilial = {this.state.codFilial} 
                                   codLinha = {this.state.codLinha}
                                   arrayFiliais = {this.state.arrayFiliais}
                                   arrayLinhasProducao = {this.state.arrayLinhasProducao}/>
          break;
      case 'Cadastrar':
        obj = <ManterProducao codFilial = {this.state.codFilial} codLinha = {this.state.codLinha} 
                              cadastrarEditar = {'cadastrar'}
                              arrayProducao = {this.state.arrayProducao} 
                              arrayProdutos = {this.state.arrayProdutos}
                              arrayFiliais = {this.state.arrayFiliais} 
                              arrayLinhasProducao = {this.state.arrayLinhasProducao} 
                              arrayProdutoLinha = {this.state.arrayProdutosLinha} />
          break;
      case 'ConsEditarExcluir':
        obj = <ConsEditaExclui codFilial = {this.state.codFilial} codLinha = {this.state.codLinha}
                               arrayProdutos = {this.state.arrayProdutos}
                               arrayProducao = {this.state.arrayProducaoEdit}
                               arrayFiliais = {this.state.arrayFiliais} 
                               arrayLinhasProducao = {this.state.arrayLinhasProducao}/>
          break;
      case 'Editar':
        obj = <ManterProducao codFilial = {this.state.codFilial} codLinha = {this.state.codLinha} 
                              cadastrarEditar = {'editar'}
                              arrayProducao = {this.state.arrayProducaoEdit} 
                              arrayProdutos = {this.state.arrayProdutos}
                              arrayFiliais = {this.state.arrayFiliais} 
                              arrayLinhasProducao = {this.state.arrayLinhasProducao}
                              arrayProdutoLinha = {this.state.arrayProdutosLinha}
                              idProducao = {this.state.idProducao} />
          break;
      case 'AlterarSenha':
        obj = <TrocaSenha />
          break;
        
    }
    return(
      <div>
        <Header>
          <Grid>
            <Grid.Column width={2}>
              <Icon name='sidebar' style={{ margin: '5px', marginTop:'20px'}} disabled={visibleMenu} 
                  onClick= {this.handleShowHideSidebar}/>
            </Grid.Column >
            <Grid.Column width={12} style={{textAlign: 'center'}}>
              <Header.Content>
                <Image src={logoGetDataInd}  style={{ width: '150px', marginTop:'20px'}} onClick={this.telaPrincipal}/>
              </Header.Content>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
          </Grid>
        </Header>
        <Divider style={{margin:'0px'}} />
        <Sidebar.Pushable>
          <Sidebar as={Menu} animation='overlay' inverted onHide={this.handleShowHideSidebar} direction='top'
                   visible={visibleMenu} width='thin'>
            <Menu.Item as='a' onClick={this.trocaSenha}>
              <Icon name='edit outline' />
                Alterar Senha
            </Menu.Item>
            <Menu.Item as='a' onClick={this.logout}>
              <Icon name='log out' />
                Sair
            </Menu.Item>
          </Sidebar>
          <Sidebar.Pusher>
            <Segment basic>
              {obj}
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

export default Principal;