import React, { Component } from 'react';
import { Grid, Header, Icon, Form, Segment, Message, Loader, TextArea} from 'semantic-ui-react'
import PubSub from 'pubsub-js';
import { getUserCod } from '../../servicos/authenticated';
import { getToken } from '../../servicos/authenticated';
import { logout } from '../../servicos/authenticated';

export default class ManterProducao extends Component {
  constructor(props){
    super(props);
    this.state = {
      erro: '',
      sucesso: '',
      cod_produto:'',
      producao:'',
      eficiencia:'',
      observacao: '',
      data:'',
      hora: '',
      cod_filial: '',
      cod_linhaProducao: '',
      idProducao:'',
      dataMin:'',
      dataMax:'',
      loader: false,
      ArrayParadaProducao: [],
      producaoEdit: true
    };
  }
  
  handleClickVoltar(){
    PubSub.publish('opcaoTela', {opcaoTela: 'MenuCadEdit' });
  }

  componentWillMount(){
    this.atualizaCampos();
  }
  
  componentDidMount(){
    let ParadaProducao = [],
        Registros
    Registros = this.props.arrayProdutos.filter((info) => {
      return info.PARADA_PRODUCAO === '*'
    });
    for (let index = 0; index < Registros.length; index++) {
      ParadaProducao.push(Registros[index].COD_PRODUTO)
    }
    this.setState({
      idProducao: this.props.idProducao,
      cod_filial: this.props.codFilial, 
      cod_linhaProducao: this.props.codLinha,
      ArrayParadaProducao: ParadaProducao
      
    });
    if (this.props.cadastrarEditar === 'editar') {
      const producaoEditar = this.props.arrayProducao.filter((filter) => {
        return filter.id === this.props.idProducao
      })
      this.setState({
        cod_produto:producaoEditar[0].COD_PRODUTO,
        producao:producaoEditar[0].PRODUCAO,
        eficiencia:producaoEditar[0].EFICIENCIA,
        observacao: producaoEditar[0].OBSERVACAO,
        data:producaoEditar[0].DATA,
        hora:producaoEditar[0].HORA,
        producaoEdit: false
        
      });
    }
  }
  
  atualizaCampos(){
    let data = new Date();
    let diaCorrente = data.getDate(),
        diaCorrenteF = diaCorrente.toString().length > 1 ? diaCorrente : "0" +diaCorrente,
        mesCorrente = data.getMonth()+1,
        mesCorrenteF = mesCorrente.toString().length > 1 ? mesCorrente : "0"+mesCorrente;
    let dataAtualFormtada = data.getFullYear()+'-'+mesCorrenteF+'-'+diaCorrenteF;
    let hora = data.getHours();
    if (hora.toString().length === 1) {
      hora = "0"+hora
    }
    let strHora = hora +':00';
    let dataMinima = new Date();
    var dia = dataMinima.getDate();
    dataMinima.setDate(dia - 5);
    let diaMinimoCorrente = dataMinima.getDate(),
        diaMinimoCorrenteF = diaMinimoCorrente.toString().length > 1 ? diaMinimoCorrente : "0" +diaMinimoCorrente,
        mesMinimoCorrente = dataMinima.getMonth()+1,
        mesMinimoCorrenteF = mesMinimoCorrente.toString().length > 1 ? mesMinimoCorrente : "0"+mesMinimoCorrente;
    let dataMinimaFormatada = dataMinima.getFullYear()+'-'+mesMinimoCorrenteF+'-'+diaMinimoCorrenteF;
    this.setState({
      observacao: '',
      data: dataAtualFormtada,
      hora: strHora,
      dataMin: dataMinimaFormatada,
      dataMax: dataAtualFormtada
    });
  }
  
  handleChangeProducao(event) {
    event.preventDefault();
    if (this.state.cod_produto !=='') {
      let qtdeProducao = event.target.value,
          capacidade = this.props.arrayProdutoLinha.filter((info) => {
            return info.COD_LINHA_PRODUCAO === this.props.codLinha &&
              info.COD_PRODUTO === this.state.cod_produto;
          }),
          eficiencia = parseInt(qtdeProducao) / parseInt(capacidade[0].CAPACIDADE) * 100;
      this.setState({ producao: event.target.value, eficiencia: Math.round(eficiencia) });
    }
  }
  
  handleChangeProduto(event) {
    event.preventDefault();
    let { ArrayParadaProducao } = this.state
    if (ArrayParadaProducao.indexOf(event.target.value) > -1) {
      this.setState({cod_produto: event.target.value, producao: '', eficiencia:'', producaoEdit: true})
    } else {
      this.setState({cod_produto: event.target.value, producaoEdit: false})
    }
  }
  
  handleSalvar = event => {
    event.preventDefault();
    this.setState({ loader: true });
    let camposValidos = false;
    let { cod_produto, producao, eficiencia, observacao, data, hora, cod_filial, 
         cod_linhaProducao, idProducao, ArrayParadaProducao } = this.state;
    const request = require('request');
    const metaEficiencia = this.props.arrayProdutoLinha.filter((info) => {
      return info.COD_PRODUTO === cod_produto && info.COD_LINHA_PRODUCAO === cod_linhaProducao
    });
    const Producao = this.props.arrayProducao.filter((info) => {
      return info.DATA === data && info.HORA === hora && info.COD_FILIAL === cod_filial && 
             info.COD_LINHA_PRODUCAO === cod_linhaProducao
    });
    //Verificações para cadastro.
    if (this.props.cadastrarEditar === 'cadastrar') {
      //Verifica se já existe registro de produção.
      if (Producao.length === 0) {
        //Verificar se é não produção.
        if (ArrayParadaProducao.indexOf(cod_produto) > -1) {
          eficiencia = 0;
          producao = 0;
          camposValidos = true;
        } else {
          if (producao !== '' && cod_produto !== '') {
            //Verifica se a eficiencia é maior ou igual a meta da linha.
            if (eficiencia >= parseInt(metaEficiencia[0].EFICIENCIA)) {
              camposValidos = true;
            } else {
              //Validar se o campo descrição está preenchido corretamente.
              if (observacao.length >= 5) {
                 camposValidos = true;
              } else {
                if (observacao.length === 0) {
                  this.setState({erro:"Digite o motivo dá baixa eficiência no campo observação.", sucesso: "", loader: false});
                } else {
                  this.setState({erro:"Motivo dá baixa eficiência muito curto.", sucesso: "", loader: false});
                }
              }
            }
          } else {
            this.setState({erro:"Preencha todos os campos!", sucesso: "", loader: false});
          }
        }
      } else {
        this.setState({erro:"Já existe registro cadastrado nessa data e horário.", sucesso: "", loader: false});
      }
      //Salva no banco
      if (camposValidos) {
        let opcoesInsert = {
          method: 'POST',
          url:'https://inteligenciaindustrial.com/prodbeer/producao/producao',
          headers: {
            'content-type': 'application/json',
            'Authorization': getToken()
          },
          body: {"data": data, "hora": hora, "producao": producao, "eficiencia": eficiencia, "observacao": observacao,
                 "cod_produto": cod_produto, "cod_filial": cod_filial, "cod_linhaProducao": cod_linhaProducao, 
                 "cod_usuario": getUserCod()},
          json: true
        };
        request(opcoesInsert, function (error, response, body) {
          if (body !== undefined) {
            if (body.Producao) {
              this.setState({erro:"", sucesso:"Produção cadastrada com sucesso", cod_produto:'', eficiencia:'', data:'', 
                             hora:'', producao:'', loader: false});
              this.atualizaCampos();
              PubSub.publish('atualizaProducao', {atualizaProducao: true });
              PubSub.publish('atualizaProducaoEdit', {atualizaProducaoEdit: true });
              PubSub.publish('opcaoTela', {opcaoTela: 'Cadastrar' });
            } else {
              if (body.error) {
                PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
                PubSub.publish('logado', { logado:false });
                this.setState({loader: false});
                logout();
              }
              this.setState({erro:"Erro ao cadastrar a produção! Tente novamente.", sucesso:"", loader: false});
            }
          } else {
            this.setState({erro: 'Não foi possivel conectar-se a internet tente novamente', loader: false});
          }
        }.bind(this));
      }
    }
    //Verificações para alteração
    if (this.props.cadastrarEditar === 'editar') {
      //Verificar se é não produção.
      if (ArrayParadaProducao.indexOf(cod_produto) > -1) {
        eficiencia = 0;
        producao = 0;
        camposValidos = true;
      } else {
        if (producao !== '' && cod_produto !== '') {
          //Verifica se a eficiencia é maior ou igual a meta da linha.
          if (eficiencia >= parseInt(metaEficiencia[0].EFICIENCIA)) {
            camposValidos = true
          } else {
            //Validar se o campo descrição está preenchido corretamente.
            if (observacao.length >= 5) {
              camposValidos = true
            } else {
              if (observacao.length === 0) {
                this.setState({erro:"Digite o motivo dá baixa eficiência no campo observação.", sucesso: "", loader: false});
              } else {
                this.setState({erro:"Motivo dá baixa eficiência muito curto.", sucesso: "", loader: false});
              }
            }
          }
        } else {
          this.setState({erro:"Preencha todos os campos!", sucesso: "", loader: false});
        }
      }
      if (camposValidos) {
        let opcoesUpdate = {
          method: 'PUT',
          url:'https://inteligenciaindustrial.com/prodbeer/producao/producao',
          headers: {
            'content-type': 'application/json',
            'Authorization': getToken()
          },
          body: {"data": data, "hora": hora, "producao": producao, "eficiencia": eficiencia, "observacao": observacao, 
                 "cod_produto": cod_produto, "cod_filial": cod_filial, "cod_linhaProducao": cod_linhaProducao, 
                 "cod_usuario": getUserCod(), 'idProducao': idProducao},
          json: true
        };
        request(opcoesUpdate, function (error, response, body) {
          if (body !== undefined) {
            if (body.Producao) {
              this.setState({erro:"", sucesso:"Produção alterada com sucesso", cod_produto:'',eficiencia:'',data:'', 
                             hora:'', producao:'', loader: false});
              this.atualizaCampos();
              PubSub.publish('atualizaProducao', {atualizaProducao: true });
              PubSub.publish('atualizaProducaoEdit', {atualizaProducaoEdit: true });
              PubSub.publish('opcaoTela', {opcaoTela: 'Editar' });
            } else {
              if (body.error) {
                PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
                PubSub.publish('logado', { logado:false });
                this.setState({loader: false});
                logout();
              }
              this.setState({erro:"Erro ao cadastrar a produção! Tente novamente.", sucesso:"", loader: false});
            }
          } else {
            this.setState({erro: 'Não foi possivel conectar-se a internet tente novamente', loader: false});
          }
        }.bind(this));
      }
    }
  };
  
  

  render() {
    const nomeFilial = this.props.arrayFiliais.filter((info) => {
            return info.COD_FILIAL === this.props.codFilial
          }),
          nomeLinha = this.props.arrayLinhasProducao.filter((info) => {
            return info.COD_LINHA_PRODUCAO === this.props.codLinha
          }),
          Produtos = this.props.arrayProdutos,
          ProdutosLinha = this.props.arrayProdutoLinha.filter((info) => {
            return info.COD_LINHA_PRODUCAO === this.props.codLinha;
          }),
          ArrayCodProdutosLinha = [],
          ArrayProdutosFiltrados = [],
          linha = {id: -1, DESC_PRODUTO: ""}
    let index;
    for (index = 0; index < ProdutosLinha.length; index++) {
      ArrayCodProdutosLinha.push(ProdutosLinha[index].COD_PRODUTO)
    }
    for (index = 0; index < Produtos.length; index++) {
      if (ArrayCodProdutosLinha.includes(Produtos[index].COD_PRODUTO)) {
        ArrayProdutosFiltrados.push(Produtos[index])
      }
    }
    if (ArrayProdutosFiltrados[0].DESC_PRODUTO !== '') {
      ArrayProdutosFiltrados.unshift(linha)
    }
    const listProdutos = ArrayProdutosFiltrados.map((prod) => {
      return (
        <option key={prod.id} value={prod.COD_PRODUTO}>{prod.DESC_PRODUTO}</option>
      );
    });
    return(
      <div>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='top'>
          <Grid.Row>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={13}>
              <Header as='h5' style={{marginTop: '0px', textAlign: 'center'}}>
                 CADASTRO DE  PRODUÇÃO <br></br> {nomeFilial[0].DESC_FILIAL} {nomeLinha[0].DESC_LINHA} 
              </Header>
            </Grid.Column>
              <Icon floated='right' name='window close' onClick={this.handleClickVoltar}/>
            <Grid.Column width={2}>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      
        <div className='login-form'>
          <style>{`body > div, 
                   body > div > div, 
                   body > div > div > div.login-form { 
                    height: 100%;
                   }
          `} </style>
          <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: '350px' }}>
              <Form size='large' onSubmit={this.handleSalvar}>
                <Segment stacked>
                  <select placeholder="Produto" value = {this.state.cod_produto} 
                          onChange={this.handleChangeProduto.bind(this)}>
                    {listProdutos}
                  </select>
                  <br></br>
                  { this.state.producaoEdit &&
                    <Form.Input placeholder='Produção' type="number" readOnly 
                              name = "producao" value = {this.state.producao}
                              onChange={this.handleChangeProducao.bind(this)}/>
                  }
                  { this.state.producaoEdit === false &&
                  <Form.Input placeholder='Produção' type="number" 
                              name = "producao" value = {this.state.producao}
                              onChange={this.handleChangeProducao.bind(this)}/>
                  }
                  <Form.Input placeholder='Eficiencia' type="number" readOnly 
                              name = "eficiencia" value = {this.state.eficiencia}
                              onChange={e => this.setState({ eficiencia: e.target.value })}/>
                  <Form.Input>
                                <TextArea placeholder='Observação'
                                          maxLength={300}
                                          name = "observacao" value = {this.state.observacao}
                                          onChange={e => this.setState({ observacao: e.target.value })}/>
                  </Form.Input>
                  <Form.Input placeholder='Data' type="date"
                              min={this.state.dataMin} max={this.state.dataMax} 
                              name = "data"  value = {this.state.data}
                              onChange={e => this.setState({ data: e.target.value })}/>
                  <Form.Input placeholder='Hora' type="time" 
                              name = "hora" value = {this.state.hora}
                              onChange={e => this.setState({ hora: e.target.value })}/>
                  <button class="ui black large fluid icon right labeled button" > SALVAR
                    <i aria-hidden="true" class="save outline icon"></i>
                  </button>
                  {this.state.loader && <Loader active />}
                </Segment>
                {this.state.erro && <Message color='red'>{this.state.erro}</Message>}
                {this.state.sucesso && <Message color='green'>{this.state.sucesso}</Message>}
              </Form>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    )
  }
}
