export const TOKEN_KEY = "@Token";
export const USER_COD = "@Cod";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUserCod = () => localStorage.getItem(USER_COD);
export const versao = "v20201005.001";

export const saveToken = token => {
    localStorage.setItem(TOKEN_KEY, token);
};
export const saveUserCod = user => {
    localStorage.setItem(USER_COD, user);
};
export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_COD);
};