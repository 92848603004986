import React, { Component } from 'react';
import { Grid, Table, Header, Icon, Button, Modal, Message} from 'semantic-ui-react'
import PubSub from 'pubsub-js';
import { getToken } from '../../servicos/authenticated';
import { logout } from '../../servicos/authenticated';

export default class ConsEditaExclui extends Component {
  constructor(props){
    super(props);
    this.state = {
      error: '',
      sucesso: '',
      ArrayProducaoFiltrada: [],
      open: false ,
      idProducao: ''
    };
  }
  
  handleClickVoltar(){
    PubSub.publish('opcaoTela', {opcaoTela: 'MenuCadEdit' });
  }
  handleClickEditar(key){
    PubSub.publish('codProducao', {codProducao: key });
    PubSub.publish('opcaoTela', {opcaoTela: 'alterProducao' });
  }
  show = (i) => {
    this.setState({ open: true, idProducao: i })
  }
  close = () => this.setState({ open: false })

  componentDidMount(){
    const ArrayProducaoFiltrada = this.props.arrayProducao.filter((filter) => {
      return filter.COD_LINHA_PRODUCAO === this.props.codLinha && 
        filter.COD_FILIAL === this.props.codFilial
    })
    this.setState({ArrayProducaoFiltrada:ArrayProducaoFiltrada});
  }

  nomeProduto(codProduto){
    const produtos = this.props.arrayProdutos;
    const Produto = produtos.filter((filter) => {
      return filter.COD_PRODUTO === codProduto
    })
    return Produto[0].DESC_PRODUTO
  }
  handleEditar = (i) => {
    console.log(i)
    PubSub.publish('idProducao', {idProducao: i });
    PubSub.publish('opcaoTela', {opcaoTela:'Editar'})
  }
  
  handleRemover = () => {
    const id = this.state.idProducao
    const request = require('request');
    let optionsDelete = {
      method: 'PUT',
      url:'https://inteligenciaindustrial.com/prodbeer/producao/producaoDelete',
      headers: {
        'content-type': 'application/json',
        'Authorization': getToken()
      },
      body: {"id": id},
      json: true
    };
    request(optionsDelete, function (error, response, body) {
      if (body.Producao) {
        this.setState({ open: false })
        PubSub.publish('atualizaProducao', {atualizaProducao: true });
        PubSub.publish('atualizaProducaoEdit', {atualizaProducaoEdit: true });
        PubSub.publish('opcaoTela', {opcaoTela: 'MenuCadEdit' });
      } else {
        if (body.error) {
          PubSub.publish('tokenInvalido', { tokenInvalido:"Token Expirou, faça o acesso novamente." });
          PubSub.publish('logado', { logado:false });
          logout()
        }
        this.setState({error:"Erro ao cadastrar a produção! Tente novamente."});
        this.setState({sucesso:""})
      }
    }.bind(this));
  }
  
  formataData = (data) =>{
    var aux = data.split('-');
    data = aux[2]+'/'+aux[1]
    return data;
  }
  
  render() {
    const nomeFilial = this.props.arrayFiliais.filter((info) => {
            return info.COD_FILIAL === this.props.codFilial
          }),
          nomeLinha = this.props.arrayLinhasProducao.filter((info) => {
            return info.COD_LINHA_PRODUCAO === this.props.codLinha
          })
    const { open } = this.state
    const formatDecimal = require('format-decimal');
    var opts =  {
      decimal   : ',',
      precision : 2,
      thousands : '.'
    }
    const producaoList = this.state.ArrayProducaoFiltrada.map(info => {
      return(
        <Table.Row key={info.id}>
          <Table.Cell style={{ width:'30px', padding:'0px', textAlign: 'center'}}>{this.formataData(info.DATA)}</Table.Cell>
          <Table.Cell style={{ width:'40px', padding:'0px', textAlign: 'center'}}>{info.HORA}</Table.Cell>
          <Table.Cell style={{ width:'120px', padding:'0px', textAlign: 'center'}}>
            {this.nomeProduto(info.COD_PRODUTO)}     
          </Table.Cell>
          <Table.Cell style={{ width:'50px', padding:'0px', textAlign: 'center'}}>{formatDecimal(parseInt(info.PRODUCAO), opts)}</Table.Cell>
          <Table.Cell style={{ width:'45px', padding:'0px', textAlign: 'center'}}>
            <Button icon="edit outline" onClick = {() => this.handleEditar(info.id)} />
          </Table.Cell>
          <Table.Cell style={{ width:'45px', padding:'0px', textAlign: 'center'}}>
            <Button icon="remove" onClick = {() => this.show(info.id)}></Button>
            <Modal size='mini' open={open} onClose={this.close}>
              <Modal.Header>Exclusão de produção</Modal.Header>
              <Modal.Content>
                <p>Você tem certeza que deseja excluir ?</p>
              </Modal.Content>
              <Modal.Actions>
                 <Button onClick={this.close} content='Não' />
                 <Button color='yellow'  content='Sim' onClick = {() => this.handleRemover()}/>
              </Modal.Actions>
            </Modal>
          </Table.Cell>
        </Table.Row>
      )
    })
    return(
      <div>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='top'>
          <Grid.Row>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={13}>
              <Header as='h5' style={{marginTop: '0px', textAlign: 'center'}}>
                ALTERAÇÃO / EXCLUSÃO <br/>{nomeFilial[0].DESC_FILIAL} {nomeLinha[0].DESC_LINHA}   
              </Header>
            </Grid.Column>
              <Icon floated='right' name='window close' onClick={this.handleClickVoltar}/>
            <Grid.Column width={2}>
            </Grid.Column>
          </Grid.Row>
            {producaoList.length <= 0 && <Message style={{textAlign: 'center', width:'350px'}}>Sem dados cadastrados nas últimas 48 horas </Message>}
            {producaoList.length > 0 && <Table unstackable style={{ padding:'0px', width:'350px'}}>
                <Table.Header style={{display: 'block', fontSize:'12px',  width:'350px'}}>
                  <Table.Row style={{textAlign: 'center',  width:'350px'}}>
                    <Table.HeaderCell style={{width:'30px', 
                                             paddingLeft:'0px',
                                             paddingRight:'0px', 
                                             textAlign: 'center'}}>Data</Table.HeaderCell>
                    <Table.HeaderCell style={{width:'40px', 
                                             paddingLeft:'0px',
                                             paddingRight:'0px', 
                                             textAlign: 'center'}}>Hora</Table.HeaderCell>
                    <Table.HeaderCell style={{width:'120px', 
                                             paddingLeft:'0px',
                                             paddingRight:'0px', 
                                             textAlign: 'center'}}>Produto</Table.HeaderCell>
                    <Table.HeaderCell style={{width:'50px', 
                                             paddingLeft:'0px',
                                             paddingRight:'0px', 
                                             textAlign: 'center'}}>Produção</Table.HeaderCell>
                    <Table.HeaderCell style={{width:'45px', 
                                             paddingLeft:'0px',
                                             paddingRight:'0px', 
                                             textAlign: 'center'}}>Editar</Table.HeaderCell>
                    <Table.HeaderCell style={{width:'45px', 
                                             paddingLeft:'0px',
                                             paddingRight:'0px', 
                                             textAlign: 'center'}}>Excluir</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body style={{ fontSize:'10px', display: 'block', height: '360px', width:'350px', overflow: 'auto'}}>
                  {producaoList}
                </Table.Body>
              </Table>}
        </Grid>
      </div>
    )
  }
}
